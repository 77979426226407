import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './Header.css';

const Header = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isVisibleCart, setIsVisibleCart] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [toggleNav, setToggleNav] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setOpacity(Math.min(scrollY / 300, 1));
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMobileMenuVisible((prev) => !prev);
        setIsVisible((prev) => !prev);
    };

    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };

    const showCart = () => {
        setIsVisibleCart(true);
    };

    const hideCart = () => {
        setIsVisibleCart(false);
    };

    const scrollToElement = (event) => {
        event.preventDefault();

        const targetId = event.target.getAttribute('href');
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
            const offset =
                targetElement.offsetTop - window.innerHeight / 2 + targetElement.clientHeight / 2;
            window.scrollTo({ top: offset, behavior: 'smooth' });
        }
    };

    return (
        <>
            <nav className="navbar sticky bg-bg">
                <div
                    className={`mx-auto ${pathname === '/' ? 'shadow-none' : 'shadow-lg'}`}
                    style={{
                        backgroundColor: `rgba(255, 255, 255, ${pathname === '/' ? opacity : 1})`,
                    }}
                >
                    <div className="main">
                        <div className="lg:w-[250px] px-[20px] py-4">
                            <Link to="/"><h1 className="text-xl font-bold uppercase text-accent">chudy przemo</h1></Link>
                        </div>
                        <div className="flex w-1/2 items-stretch justify-center lg:w-3/4">
                            <ul className="navbar-nav w-full">
                                <li>
                                    <Link to="/o-hotelu" className="hidden text-accent lg:flex">O Hotelu</Link>
                                </li>
                                <li>
                                    <a href="#lokalizacja" onClick={scrollToElement} className="hidden text-accent lg:flex">Lokalizacja</a>
                                </li>
                                <li>
                                    <a href="#opinie" onClick={scrollToElement} className="hidden text-accent lg:flex">Opinie</a>
                                </li>
                                <li>
                                    <a href="#pokoje" onClick={scrollToElement} className="hidden text-accent lg:flex">Pokoje</a>
                                </li>
                                <li>
                                    <a href="#kontakt" onClick={scrollToElement} className="hidden text-accent lg:flex">Kontakt</a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-[250px] hidden lg:flex px-[20px] py-10">
                            <Link
                                to="/rezerwacja"
                                className="border rounded-full hover:bg-accent hh transition-colors hover:text-bg border-dashed border-accent px-6 py-3"
                            >
                                Zarezerwuj
                            </Link>
                        </div>

                        <svg
                            className={`ham lg:hidden block ham6 ${isVisible ? 'active' : ''}`}
                            viewBox="0 0 100 100"
                            width="80"
                            onClick={toggleMenu}
                        >
                            <path
                                className="line stroke-main top"
                                d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"
                            />
                            <path
                                className="line stroke-main middle"
                                d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"
                            />
                            <path
                                className="line stroke-main bottom"
                                d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"
                            />
                        </svg>
                    </div>
                </div>
                {isVisible && (
                    <CSSTransition in={isVisible} timeout={300} classNames="navbar-nav-hamburger" unmountOnExit>
                        <ul className="navbar-nav-hamburger xl:hidden">
                            <div className="padding">
                                <li>
                                    <a href="/o-hotelu" className="text-accent">O Hotelu</a>
                                </li>
                                <li>
                                    <a href="#lokalizacja" onClick={scrollToElement}
                                       className="text-accent">Lokalizacja</a>
                                </li>
                                <li>
                                    <a href="#opinie" onClick={scrollToElement} className="text-accent">Opinie</a>
                                </li>
                                <li>
                                    <a href="#pokoje" onClick={scrollToElement} className="text-accent">Pokoje</a>
                                </li>
                                <li>
                                    <a href="#kontakt" onClick={scrollToElement} className="text-accent">Kontakt</a>
                                </li>
                            </div>
                        </ul>
                    </CSSTransition>
                )}
            </nav>
        </>
    );
};

export default Header;
