import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        phone: '',
        birthdate: '',
        acceptTerms: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.acceptTerms) {
            alert('You must accept the terms and conditions');
            return;
        }

        try {
            const response = await axios.post('http://localhost:5000/register', formData);
            console.log(response.data);
            alert('Registration successful!');
        } catch (error) {
            console.error('Error registering user:', error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-bg">
            <form onSubmit={handleSubmit} className="bg-second p-8 rounded shadow-md">
                <h2 className="text-accent text-2xl mb-6">Register</h2>
                <div className="mb-4">
                    <label className="block text-accent">Email:</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded mt-1" required />
                </div>
                <div className="mb-4">
                    <label className="block text-accent">Name:</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded mt-1" required />
                </div>
                <div className="mb-4">
                    <label className="block text-accent">Password:</label>
                    <input type="password" name="password" value={formData.password} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded mt-1" required />
                </div>
                <div className="mb-4">
                    <label className="block text-accent">Phone:</label>
                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded mt-1" required />
                </div>
                <div className="mb-4">
                    <label className="block text-accent">Birthdate:</label>
                    <input type="date" name="birthdate" value={formData.birthdate} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded mt-1" required />
                </div>
                <div className="mb-4">
                    <label className="flex items-center">
                        <input type="checkbox" name="acceptTerms" checked={formData.acceptTerms} onChange={handleChange} className="mr-2" />
                        <span className="text-accent">Accept terms and conditions</span>
                    </label>
                </div>
                <button type="submit" className="w-full bg-main text-bg py-2 px-4 rounded">Register</button>
            </form>
        </div>
    );
};

export default Register;
