import Zdj from '../../Lib/o-hotelu.png';

export default function OHotelu() {
    return (
        <div className="mt-10 bg-main py-10 rounded-t-2xl">
            <div className="lg:w-3/4 lg:p-0 p-4 mx-auto">
                <div className="flex gap-10">
                    <div className="w-1/2 lg:block hidden">
                        <img src={Zdj} className="aspect-video rounded-xl w-full h-auto" alt="Zjecie" />
                    </div>
                    <div className="lg:w-1/2 h-full">
                        <div className="lg:p-0 h-full flex flex-col gap-10 text-white p-6 bg-cover bg-center lg:bg-transparent">
                            <h1 className="text-2xl">O Hotelu</h1>
                            <p>
                                "Chudy Przemo" to urokliwy kącik w Rycerce Dolnej. Oferuje przytulne pokoje z
                                zapierającymi dech w piersiach widokami i bliskość krystalicznej rzeki. Idealny dla
                                miłośników ciszy, natury i wypoczynku. Zapraszamy!
                            </p>
                            <a href="/o-hotelu" className="border w-fit rounded-full border-dashed border-white px-6 py-3">
                                Czytaj więcej
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
