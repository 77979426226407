import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './OpinionForm.css';

const OpinionForm = ({ isAuthenticated }) => {
    const [formData, setFormData] = useState({
        name: '',
        title: '',
        link: '',
        number: '' // Zmieniamy 0 na pusty string
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Sprawdzamy, czy liczba gwiazdek została wybrana
        if (!formData.number) {
            alert('Proszę wybrać liczbę gwiazdek');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                alert('Brak tokenu, proszę się zalogować.');
                navigate('/login');
                return;
            }

            const response = await axios.post('http://localhost:5000/opinions', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log(response.data);
            alert('Opinia została wysłana pomyślnie!');
        } catch (error) {
            console.error('Błąd przy wysyłaniu opinii:', error);
        }
    };

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    const handleRegisterRedirect = () => {
        navigate('/register');
    };

    return (
        <div className="relative flex flex-col items-center justify-center bg-bg opinion-form-container">
            <h2 className="text-accent text-3xl mb-6 text-center">Prześlij opinię</h2>
            {isAuthenticated ? (
                <form onSubmit={handleSubmit} className="bg-second p-8 rounded-lg shadow-md w-full max-w-md opinion-form">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-4">
                            <label className="block text-accent mb-1">Imię:</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-accent mb-1">Tytuł:</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                                required
                            />
                        </div>
                        <div className="mb-4 col-span-2">
                            <label className="block text-accent mb-1">Komentarz:</label>
                            <textarea
                                name="link"
                                value={formData.link}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                                required
                            ></textarea>
                        </div>
                        <div className="mb-4 col-span-2">
                            <label className="block text-accent mb-1">Ocena (1-5):</label>
                            <input
                                type="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
                                min="1"
                                max="5"
                                required
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-main text-bg py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50 transition duration-200 hover:bg-accent"
                    >
                        Wyślij
                    </button>
                </form>
            ) : (
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4 rounded-lg opinion-form-overlay">
                    <p className="text-2xl mb-4 text-center">Aby zamieścić opinię, proszę się zalogować lub zarejestrować</p>
                    <div className="flex space-x-4">
                        <button onClick={handleLoginRedirect} className="bg-main text-bg py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50 transition duration-200 hover:bg-accent">
                            Zaloguj się
                        </button>
                        <button onClick={handleRegisterRedirect} className="bg-main text-bg py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50 transition duration-200 hover:bg-accent">
                            Zarejestruj się
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OpinionForm;
