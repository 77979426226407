import Zdj from '../../Lib/o-hotelu.png';

export default function Pokoje() {
    return (
        <div className=" bg-main py-10">
            <div className="lg:w-3/4 lg:p-0 p-4 mx-auto">
                <div className="flex flex-row-reverse gap-10">
                    <div className="w-1/2 lg:block hidden">
                        <img src={Zdj} className="aspect-video rounded-xl w-full h-auto" alt="" />
                    </div>
                    <div className="lg:w-1/2 h-full">
                        <div
                            className="lg:p-0 h-full flex flex-col gap-10 text-white p-6 bg-cover bg-center lg:bg-transparent"
                        >
                            <h1 className="text-2xl">Pokoje</h1>
                            <p>
                                Hotel "Chudy Przemo" w Rycerce Dolnej zaprasza do komfortowych pokoi z darmowym Wi-Fi,
                                oferujących malownicze widoki i bliskość szumiącej rzeki. Idealne miejsce dla ceniących
                                spokój i połączenie z naturą. Ciesz się urokiem górskiej okolicy i nowoczesnymi
                                udogodnieniami. Zapraszamy!
                            </p>
                            <a
                                href="/rezerwacja"
                                className="border w-fit rounded-full border-dashed border-white px-6 py-3"
                            >
                                Sprawdź dostępność
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}