import React from 'react';
import './footer.css';

const block = [
    {
        link: '/o-hotelu',
        text: 'O hotelu'
    },
    {
        link: '#lokalizacja',
        text: 'Lokalizacja'
    },
    {
        link: '#opienie',
        text: 'Opienie'
    },
    {
        link: '#pokoje',
        text: 'Pokoje'
    },
    {
        link: '#kontakt',
        text: 'Kontakt'
    }
];

const Kontakt = () => {
    return (
        <div className="footer w-full bg-bg mt-35" id="kontakt">
            <div className="mt-20">
                <div className="border-t border-dashed border-black">
                    <div className="lg:p-10 p-4">
                        <div className="flex gap-10 flex-col-reverse lg:flex-row">
                            <div className="lg:w-1/2" style={{ paddingLeft: '20px' }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2592.237224487196!2d19.064343076908653!3d49.48002575675913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47143763f12a835b%3A0x638612fa1fa2680b!2sRycerka%20Dolna%20270A%2C%2034-370%20Rycerka%20Dolna!5e0!3m2!1spl!2spl!4v1713222428929!5m2!1spl!2spl"
                                    width="auto"
                                    height="auto"
                                    className="lg:w-2/3 w-full mx-auto h-[300px] rounded-md"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                            <div className="lg:w-1/2">
                                <div className="flex justify-between lg:gap-0 gap-10 items-center flex-col h-full">
                                    <div className="flex flex-row gap-4">
                                        {block.map((item) => (
                                            <a key={item.link} href={item.link} className="font-semibold text-accent">
                                                {item.text}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="flex gap-4">
                                        <a href="/zarezerwuj" className="border hover:bg-accent hover:text-white hh rounded-full border-dashed border-accent px-6 py-3">Zarezerwuj teraz</a>
                                    </div>
                                    <div className="flex flex-row gap-8">
                                        <div className="flex flex-col gap-4">
                                            <h1 className="font-semibold">Kontakt</h1>
                                            <div className="text-[#070707] opacity-50">
                                                <p>megaprzemo@chudy-przemo.pl</p>
                                                <p>+48 123 672 888</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-4">
                                            <h1 className="font-semibold">Adres</h1>
                                            <div className="text-[#070707] opacity-50">
                                                <p>Rycerka Dolna 270A, 34-370 Rycerka Dolna</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kontakt;
