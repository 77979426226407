import React, { useState } from 'react';
import axios from 'axios';

const Login = ({ setAuth }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/login', formData);
            const { token } = response.data;
            localStorage.setItem('token', token);
            setAuth(true);
            alert('Login successful!');
        } catch (error) {
            console.error('Error logging in:', error);
            alert('Invalid credentials');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-bg">
            <form onSubmit={handleSubmit} className="bg-second p-8 rounded shadow-md">
                <h2 className="text-accent text-2xl mb-6">Login</h2>
                <div className="mb-4">
                    <label className="block text-accent">Email:</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded mt-1" required />
                </div>
                <div className="mb-4">
                    <label className="block text-accent">Password:</label>
                    <input type="password" name="password" value={formData.password} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded mt-1" required />
                </div>
                <button type="submit" className="w-full bg-main text-bg py-2 px-4 rounded">Login</button>
            </form>
        </div>
    );
};

export default Login;
