import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
import HomePage from './pages/Home/HomePage';
import AboutHotelPage from './pages/O-Hotelu/AboutHotelPage';
import Register from './pages/Users/Register';
import Login from './pages/Users/Login';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage isAuthenticated={isAuthenticated} />} />
                    <Route path="/o-hotelu" element={<AboutHotelPage />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login setAuth={setIsAuthenticated} />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
