import React from 'react';
import Wnetrze from './wnetrze';
import Zewnatrz from './zewnatrz';
import Udogodnienia from './udogodnienia';

import Zdj from '../Lib/o-hotelu.png';

function AboutHotelPage() {
    return (
        <div style={{ backgroundColor: '#F8EDE2', paddingBottom: '10px' }}>
            <div className="lg:p-10 p-4">
                <h1 className="py-10 text-6xl text-center">O Hotelu</h1>
                <div className="lg:w-3/4 mx-auto">
                    <img src={Zdj} className="lg:w-3/4 mx-auto h-auto aspect-video rounded-md" alt="Hotel" />
                </div>
            </div>
            <div style={{ backgroundColor: '#F8EDE2' }}>
                <Wnetrze />
            </div>
            <div style={{ backgroundColor: '#F8EDE2' }}>
                <Zewnatrz />
            </div>
            <div style={{ backgroundColor: '#F8EDE2' }}>
                <Udogodnienia />
            </div>
        </div>
    );
}

export default AboutHotelPage;
