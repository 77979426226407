import Zdj from '../../Lib/przemo-bg.jpg';
import './FirstElement.css';

export default function FirstElement() {
    return (
        <div className="tlo-ele">
            <div className="first-element-container">
                <div className="image-wrapper">
                    <img src={Zdj} className="image" alt="" />
                </div>
            </div>
        </div>
    );
}
