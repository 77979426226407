import React from 'react';
import Zdj1 from '../Lib/udogodnienia.png';

function Udogodnienia() {
    return (
        <div className="bg-main py-10">
            <div className="lg:w-3/4 lg:p-0 p-4 text-white mx-auto">
                <h1 className="text-4xl">Udogodnienia</h1>
                <p className="text-2xl">Hotel Chudy Przemo zapewnia miejsce pełne wygody.</p>
                <div className="my-10 py-10 flex flex-col lg:flex-row gap-10">
                    <div className="lg:w-1/2">
                        <img src={Zdj1} alt="udogodnienia" className="w-2/3 mx-auto" />
                    </div>
                    <div className="lg:w-1/2 text-2xl flex flex-col gap-4">
                        <p>Dostęp do hotelu bez użycia kluczy</p>
                        <p>Profesjonalne sprzątanie</p>
                        <p>W pełni wyposażona kuchnia</p>
                        <p>Świeże ręczniki i pościel</p>
                        <p>Środki czystości</p>
                        <p>Całodobowa infolinia</p>
                        <p>Miejsce parkingowe</p>
                        <p>Wi-Fi</p>
                    </div>
                </div>
                <div className="text-center flex flex-col gap-4">
                    <p>W razie jakichkolwiek pytań lub próśb prosimy o kontakt.</p>
                    <a
                        href="/#kontakt"
                        className="border hover:bg-accent hover:text-white hh rounded-full border-dashed w-fit mx-auto border-accent px-6 py-3"
                    >
                        Skontaktuj się z nami
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Udogodnienia;
