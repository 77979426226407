import React from 'react';
import FirstElement from './FirstElement/FirstElement';
import OHotelu from './OHotelu/O-hotelu';
import Pokoje from './Pokoje/Pokoje';
import Opinie from './Opinie/Opinie';
import Lokalizacja from './Lokalizacja/Lokalizacja';
import OpinionForm from './Opine-form/OpinionForm';
import CookieBanner from './CookieBanner/CookieBanner';

export default function HomePage({ isAuthenticated }) {
    return (
        <div style={{ backgroundColor: '#F8EDE2' }}>
            <FirstElement />
            <OHotelu />
            <Pokoje />
            <Opinie />
            <OpinionForm isAuthenticated={isAuthenticated} />
            <Lokalizacja />
            <CookieBanner />
        </div>
    );
}
