import React, { useEffect, createContext, useContext } from 'react';
import '../app.css';
import Header from './Home/Header/Header';
import Footer from './Home/Footer/Footer';
import { register } from 'swiper/element/bundle';

const PageContext = createContext(null);

export const usePage = () => useContext(PageContext);

const Layout = (props) => {
    const page = null;

    useEffect(() => {
        register();
        window.debug = {};
    }, []);

    return (
        <PageContext.Provider value={page}>
            <div>
                <Header />
                {props.children}
                <Footer />
            </div>
        </PageContext.Provider>
    );
}

export default Layout;
