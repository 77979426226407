import React, { useState } from 'react';
import cookieImage from '../../Lib/cookie.png';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    const handleAccept = () => {
        // Logika zapisywania zgody użytkownika np. do localStorage
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
    };

    const handlePreferences = () => {
        // Logika otwierania okna preferencji
        alert('Open preferences dialog');
    };

    if (!showBanner) return null;

    return (
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-white shadow-md rounded-md mx-auto max-w-md flex justify-between items-center">
            <div>
                <h2 className="font-bold text-lg">Cookies</h2>
                <p className="text-sm">
                    We use cookies and similar technologies to help personalise content, tailor and measure ads, and provide a better experience. By clicking accept, you agree to this, as outlined in our Cookies Policy.
                </p>
                <div className="mt-2 flex space-x-2">
                    <button onClick={handleAccept} className="bg-black text-white px-4 py-2 rounded">Accept</button>
                    <button onClick={handlePreferences} className="bg-gray-200 text-black px-4 py-2 rounded">Preferences</button>
                </div>
            </div>
            <img src={cookieImage} alt="cookie" className="w-20 h-20" />
            <button onClick={() => setShowBanner(false)} className="absolute top-0 right-0 p-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    );
};

export default CookieBanner;
